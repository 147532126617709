.faqs-container {
  display: flex;
  height: 100%;

  .faq-section-label {
    margin: 20px 0 10px;
    font-weight: bold;
    font-size: 18px;
  }

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .fields-block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    .ant-form-item {
      width: 48%;
      .ant-row {
        display: block;
      }
    }
  }
}

.faqs-configuration-section {
  width: 100%;
  padding: 100px 12% 50px;
  margin: 0 auto 5%;
  box-sizing: content-box;
  .fields-block {
    .ant-form-item {
      width: 48%;
      .ant-row {
        display: block;
      }
    }
  }
}
